window.jQuery = require("jquery");
window.$ = window.jQuery;
//This code been added by Ab after anwer here
//https://stackoverflow.com/questions/46094912/added-non-passive-event-listener-to-a-scroll-blocking-touchstart-event

/*
jQuery.event.special.touchstart = {
  setup: function( _, ns, handle ){
    if ( ns.includes("noPreventDefault") ) {
      this.addEventListener("touchstart", handle, { passive: false });
    } else {
      this.addEventListener("touchstart", handle, { passive: true });
    }
  }
};
jQuery.event.special.touchmove = {
  setup: function( _, ns, handle ){
    if ( ns.includes("noPreventDefault") ) {
      this.addEventListener("touchmove", handle, { passive: false });
    } else {
      this.addEventListener("touchmove", handle, { passive: true });
    }
  }
};
*/

require('webpack-jquery-ui');


//require('jquery-simple-pagination');  I think we don't need for this one ...
require('twbs-pagination');

require("bootstrap");
require('popper.js');
require("bootstrap/dist/js/bootstrap.min.js");
require("bootstrap/dist/css/bootstrap.min.css");
//require("./../css/login.css");

require("./../css/market/header-footer.css");
//require("./../css/market/home_page.css");

//require("../../../public/css/market/header-footer.css");
//require("../../../public/css/market/home_page.css");




/*
//Store the original reference of console.error
var orgError = console.error;
//Overwirte the default function
console.error = function(error) {
  //All error will be intercepted here
  alert("Intercepted -> " + error);
  //Invoke the original console.error to show the message in console
  return orgError(error);
}


var orgError = console.warn;
//Overwirte the default function
console.warn = function(error) {
  //All error will be intercepted here
  alert("Intercepted -> " + error);
  //Invoke the original console.error to show the message in console
  return orgError(error);
}

*/
/*

// define a new console
var console=(function(oldCons){
    return {
        log: function(text){
            oldCons.log(text);

            // Your code
        },
        info: function (text) {
            oldCons.info(text);
            alert(text);
            // Your code
        },
        warn: function (text) {
            oldCons.warn(text);
            alert(text);
            // Your code
        },
        error: function (text) {
            oldCons.error(text);
            alert(text);
            // Your code
        }
    };
}(window.console));
//Then redefine the old console
window.console = console;
*/

/*
var old_console_log = console.log;
console.log = function() {
        old_console_log.apply(this, arguments);
  //      alert('log '  + arguments);
  }

  var old_console_warn = console.warn;
  console.warn = function() {
          old_console_warn.apply(this, arguments);
          alert('warn '  + arguments);
    }
*/

import * as Sentry from "@sentry/browser";
import { CaptureConsole } from "@sentry/integrations";
import { ReportingObserver as ReportingObserverIntegration } from "@sentry/integrations";

import { Integrations } from "@sentry/tracing";
//import { addBreadcrumb, Severity } from '@sentry/react';
import { Debug as DebugIntegration } from "@sentry/integrations";
import { Offline as OfflineIntegration } from "@sentry/integrations";
import { vueIntegration } from "@sentry/vue";


Sentry.init({
  dsn: "https://297356c35c64424e8a049ee86b7c3d0f@o376938.ingest.sentry.io/4504062000496640",

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    release: "fastorder@2.3.12",
    integrations: [new Integrations.BrowserTracing(), new CaptureConsole(
      {
        levels: ['log', 'info', 'warn', 'error', 'debug', 'assert']
      }
    ), new ReportingObserverIntegration(), new DebugIntegration(), new OfflineIntegration()],
    maxBreadcrumbs: 50,
    enableInExpoDevelopment: true,
    debug: false,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});



window.Swal = require("sweetalert2");
//require('webpack-jquery-ui/css');  //ommit, if you don't want to load basic css theme



//
//import 'owl.carousel/dist/assets/owl.theme.default.css';



//disabled in 2022-11-02
//require("../../../public/css/market/owl.carousel.css");
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel';

let owl_carousel = require('owl.carousel');
window.fn = owl_carousel;
require('owl.carousel/dist/assets/owl.carousel.css');

//import owlCarousel from "owl.carousel";

require("@fortawesome/fontawesome-free/css/fontawesome.css");
require("@fortawesome/fontawesome-free/css/solid.css");
require("bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js");
require("bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css");
require("bootstrap-select/dist/js/bootstrap-select.min.js");
require("bootstrap-select/dist/css/bootstrap-select.min.css");


//require("moment");
//require("bootstrap-hijri-datepicker/dist/js/bootstrap-hijri-datetimepicker.min.js");
//require("bootstrap-hijri-datepicker/dist/css/bootstrap-hijri-datetimepicker.min.css");


/*
const SentryCliPlugin = require('@sentry/webpack-plugin');


const config = {
  plugins: [
    new SentryCliPlugin({
      include: '.',
      ignoreFile: '.sentrycliignore',
      ignore: ['node_modules', 'webpack.config.js'],
      configFile: 'sentry.properties',
    }),
  ],
};

*/

/*
//remap console to some other output
var console = (function(oldCons){
    return {
        log: function(text){
            oldCons.log(text);
            //custom code here to be using the 'text' variable
            //for example: var content = text;
            //document.getElementById(id).innerHTML = content
        },
        info: function (text) {
            //oldCons.info(text);
            //custom code here to be using the 'text' variable
        },
        warn: function (text) {
            oldCons.warn('AbTest' + text);
            //custom code here to be using the 'text' variable
        },
        error: function (text) {
            oldCons.error(text);
           //custom code here to be using the 'text' variable
        }
    };
}(window.console));

//Then redefine the old console
window.console = console;
*/

/*
Sentry.captureMessage("TEST message", {
  level: Severity.Warning
}); // Default SentrySeverity.Error


Sentry.configureScope(function(scope) {
  scope.setLevel("warning");
  //scope.setLevel("warn");

  Sentry.captureException(new Error("custom error 33"));

});
*/
/*

*/
/*
const SentryCliPlugin = require('@sentry/webpack-plugin');

const config = {
  plugins: [
    new SentryCliPlugin({
      include: '.',
      ignoreFile: '.sentrycliignore',
      ignore: ['node_modules', 'webpack.config.js'],
      configFile: 'sentry.properties',
    }),
  ],
};
*/
//\this one produing errors
/*
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
Sentry.init({
    dsn: "https://611b02373f954521af57da307771b58a@sentry.fastorder.com/11",

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    release: "fastorder@2.3.12",
    integrations: [new Integrations.BrowserTracing()],
    maxBreadcrumbs: 50,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});
*/

//var client = new raven.Client('{{ SENTRY_DSN }}');
/*
Raven.config("https://297356c35c64424e8a049ee86b7c3d0f@o376938.ingest.sentry.io/4504062000496640").install();
Raven.context(function() {
  // all your stuff goes here
});
*/

window.save_data_attribute = function(contractid, k, k_attribute, v_attribute, k_type, ajax_giving_data)
{

  if (contractid && k && k_attribute && k_type && v_attribute)
  {

  var url = "https://project.dev.fastorder.com/api/v3" + '/contract/save_data_attribute';
//alert('url ' + url);
var ajax_data = {
  type: "POST",
  async: false,
  url: url,
  datatype: 'json',
  data: {contractid: contractid, k: k, k_attribute: k_attribute, v_attribute: v_attribute, k_type: k_type},
  success: function(e) { document.location.reload(); },
  error: function (e) {

alert(e.status + ' ' + e.statusText);
alert(e.responseText + ' ' + e.Value);
// 			$.each(e, function( k, v ) {
//   alert( "Key: " + k + ", Value: " + v );
// });
//Key: responseText, Value:
  }
};

if (ajax_giving_data.success != null) {
  ajax_data.success = ajax_giving_data.success;
}



if (ajax_giving_data.error != null) {
  ajax_data.error = ajax_giving_data.error;
}

	$.ajax(ajax_data);

}


}


window.loadApiBlock1 = function(route, div_name, json_request)
{

json_request['with_template'] = true;


//{ name: "John", location: "Boston" }
//alert('loadItemBlock1 01 ' + block_template('sss'));
//alert(json_request);
	var url = "https://project.dev.fastorder.com/api/v3" + route;
	$.ajax({
		type: "GET",
		async: false,
		url: url,
		datatype: 'json',
		data: json_request,
		success: function (htmlText) {

			$('#' + div_name).html(htmlText);
			//$('.carousel').carousel();


		},
		error: function (e) {
alert('error');
//alert(e.status + ' ' + e.statusText);
 			$.each(e, function( k, v ) {
   alert( "Key: " + k + ", Value: " + v );
 });

		}
	});
}
